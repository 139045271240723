<template>
    <div id="Reports" :class="{isFilterOpen: checkFilterOpen}">
        <loader-component v-if="showLoader"></loader-component>
        <mds-layout-grid>
            <mds-row style="margin: 0; padding: 0">
                <div
                    class="leftBlock"
                    :class="{ showFilterLeftBlock: showFilterPanel }"
                    :style="cssVariables"
                >
                    <div class="reportsHeaderSection">
                        <div>
                            <h2 class="reports-header">Reports</h2>
                        </div>
                        <div
                            class="headerActionButton"
                            style="margin-top: 25px"
                        >
                            <header-button-panel
                                :buttonDetails="buttonPanelDetails"
                                :counterDetails="counterDetails"
                                @panelButtonClicked="onClickPanelButton"
                            ></header-button-panel>
                        </div>
                    </div>
                    <div class="reportsTable">
                        <div
                            style="
                                margin: 0px;
                                height: auto;
                                max-height: 70vh;
                                overflow: auto;
                                margin-top: 13px;
                            "
                        >
                            <mds-table
                                multiselection
                                show-sortable
                                row-hover
                                v-if="reportLength > 0"
                            >
                                <mds-thead
                                    @mds-thead-multiselection-changed="
                                        handleMultiSelection($event)
                                    "
                                    :indeterminate="
                                        selectionStatus.isIndeterminate
                                    "
                                    :checked="selectionStatus.isAllSelected"
                                >
                                    <mds-th
                                        v-for="(header, index) in reportsHeader"
                                        :key="index"
                                        :sortable="header.sortable"
                                        :sorted="header.sorted"
                                        :width="header.width"
                                        :right-aligned="
                                            header.align === 'right'
                                        "
                                        @mds-th-sort-changed="
                                            handleSortEvent(
                                                index,
                                                header.fieldName,
                                                $event
                                            )
                                        "
                                    >
                                        {{ header.text }}
                                    </mds-th>
                                </mds-thead>
                                <mds-tbody>
                                    <mds-tr
                                        v-for="(row, index) in reportsRow"
                                        :key="index"
                                        :checked="row.checked"
                                        :multiselection-label="row.name"
                                        @mds-tr-multiselection-changed="
                                            handleRowSelectEvent(
                                                index,
                                                $event,
                                                row
                                            )
                                        "
                                    >
                                        <mds-td
                                            v-for="(header, i) in reportsHeader"
                                            :key="i"
                                            :right-aligned="
                                                header.align === 'right'
                                            "
                                        >
                                            <template
                                                v-if="
                                                    header.fieldName ===
                                                    'actions'
                                                "
                                            >
                                                <span>
                                                    <mds-button
                                                        v-if="row.status == 'Completed'"
                                                        :disabled="
                                                            downloadActionButton 
                                                                || !(permissions.includes('reports-download')
                                                                || permissions.includes('*'))
                                                        "
                                                        v-on:click="
                                                            singalDownloadActionButton(
                                                                row
                                                            )
                                                        "
                                                        class="actionButton"
                                                        icon="download"
                                                        variation="icon-only"
                                                        type="button"
                                                    ></mds-button>
                                                    <mds-button
                                                        v-else
                                                        disabled
                                                        class="actionButton"
                                                        icon="download"
                                                        variation="icon-only"
                                                        type="button"
                                                    ></mds-button>
                                                    </span>
                                                <span :key="actionButtonKey"
                                                    ><mds-button
                                                        :disabled="
                                                            refreshActionButton 
                                                                || !(permissions.includes('reports-re-run-report')
                                                                || permissions.includes('*'))
                                                        "
                                                        v-on:click="
                                                            singalRefreshActionButton(
                                                                row
                                                            )
                                                        "
                                                        class="actionButton"
                                                        icon="refresh"
                                                        variation="icon-only"
                                                        type="button"
                                                    ></mds-button
                                                ></span>
                                                <span
                                                    ><mds-button
                                                        :disabled="
                                                            deleteActionButton 
                                                                || !(permissions.includes('reports-delete')
                                                                || permissions.includes('*'))"
                                                        v-on:click="
                                                            singalDeleteActionButton(
                                                                row
                                                            )
                                                        "
                                                        @click="
                                                            toggleDelete =
                                                                !toggleDelete
                                                        "
                                                        class="actionButton"
                                                        icon="trash"
                                                        variation="icon-only"
                                                        type="button"
                                                    ></mds-button
                                                ></span>
                                            </template>
                                            <template
                                                v-if="
                                                    header.fieldName ===
                                                    'submitdatetime'
                                                "
                                            >
                                                {{
                                                    convertDate(
                                                        row[header.fieldName]
                                                    )
                                                }}
                                            </template>
                                            <template v-else>
                                                {{ row[header.fieldName] }}
                                            </template>
                                        </mds-td>
                                    </mds-tr>
                                </mds-tbody>
                            </mds-table>
                        </div>
                        <mds-pagination
                            :class="[
                                showFilterPanel === true
                                    ? 'move-pagination-show-info'
                                    : '',
                                'pagination-component',
                            ]"
                            v-if="reportLength > 0"
                            :key="paginationComponentKey"
                            show-items-info
                            show-items-select
                            :total-items="totalItems"
                            :pageSize="pageSize"
                            :pageSizes="[10, 20, 50]"
                            @mds-pagination-page-changed="paginateTable"
                            style="margin-top: 10px; margin-right: 3px"
                        ></mds-pagination>
                        <mds-empty-state
                            v-if="reportsRow == undefined"
                            class="no-results-message"
                            size="medium"
                            title="No results matched"
                            message="We weren't able to match any results with your current selections. Please adjust your filter criteria and try again."
                        ></mds-empty-state>
                    </div>
                </div>

                <div
                    class="rightBlock"
                    :class="{ showFilterRightBlock: showFilterPanel }"
                    :style="cssVariables"
                >
                    <div class="filterPanel">
                        <transition-group name="fade" class="group-4">
                            <filter-panel
                                ref="fiterpanel"
                                :key="checkBoxFilterKey"
                                :filterData="filterObject"
                                @dateRange="dateRange"
                                @multiSelectFilterChange="
                                    multiSelectFilterChange
                                "
                                @hideFilterPanel="closeFilterPanel"
                                :isFilterPanelOpen="showFilterPanel"
                                @showFilterPanel="openFilterPannel"
                                :selectCheckboxId="checkboxFilterId"
                                :showSearchInput="false"
                                @clearAllFilters="clearAllFilters"
                                searchInputLable="Reports Name"
                            ></filter-panel>
                        </transition-group>
                    </div>
                </div>
            </mds-row>
        </mds-layout-grid>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>

        <!-- Delete Popup -->
        <mds-dialog
            v-model="toggleDelete"
            action-required
            title="Delete this report?"
        >
            This action cannot be undone, and the report will be deleted for all
            users with access.
            <template v-slot:mds-dialog-supplemental-content>
                <mds-form>
                    <mds-fieldset>
                        <mds-checkbox
                            value="deleteReportChecked"
                            @change="deleteReportChecked($event)"
                            >Yes, delete this report.</mds-checkbox
                        >
                    </mds-fieldset>
                </mds-form>
            </template>
            <template v-slot:mds-dialog-actions-right>
                <mds-button-container right-aligned>
                    <mds-button
                        type="button"
                        variation="secondary"
                        @click="toggleDelete = !toggleDelete"
                        v-on:click="deleteReportNo()"
                    >
                        Cancel
                    </mds-button>
                    <mds-button
                        type="button"
                        style="background-color: #ff0000; color: white"
                        @click="toggleDelete = !toggleDelete"
                        :disabled="deleteReportButtonDisable"
                        v-on:click="deleteReportYes()"
                    >
                        Delete report
                    </mds-button>
                </mds-button-container>
            </template>
        </mds-dialog>
    </div>
</template>

<script>
import get from "lodash/get";
import { MdsLayoutGrid, MdsRow } from "@mds/layout-grid";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTr,
    MdsTbody,
    MdsTd,
} from "@mds/data-table-4.0.2";
import FilterPanel from "../common_components/FilterPanel.vue";
import NotificationComponent from "../ui_component/notificationComponent.vue";
import LoaderComponent from "../ui_component/loaderComponent.vue";
import MdsPagination from "@mds/pagination";
import HeaderButtonPanel from "../common_components/HeaderButtonPanel.vue";
import MdsEmptyState from "@mds/empty-state";
import { mapActions, mapGetters, mapState } from "vuex";
import MdsDialog from "@mds/dialog";
import MdsForm from "@mds/form";
import MdsCheckbox from "@mds/checkbox";
import MdsFieldset from "@mds/fieldset";
import store from "@/store";
import { REPORTING_ACTIONS } from "@/store/modules/reporting/constants";
import { dateFormatterMixin } from "../../mixins/date.js";
import {
    deleteReport,
    downloadReport,
    downloadReports,
    reRunReport
} from "../../services/reporting_service.js";

export default {
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsTable,
        MdsThead,
        MdsTh,
        MdsTr,
        MdsTbody,
        MdsTd,
        MdsButton,
        MdsButtonContainer,
        MdsPagination,
        FilterPanel,
        NotificationComponent,
        LoaderComponent,
        HeaderButtonPanel,
        MdsEmptyState,
        MdsDialog,
        MdsForm,
        MdsFieldset,
        MdsCheckbox,
    },
    beforeCreate() {
        this.$store = store;
    },
    mixins: [dateFormatterMixin],

    data() {
        return {
            shareUniverseTableHover: true,
            searchTextInputField: "",
            currentActive: 1,
            checkboxFilterId: [],
            showFilterPanel: false,
            isFilterPanelOpen: true,
            showEnableDisable: true,
            checkBoxFilterKey: 0,
            toggleID: 0,
            checkFilterOpen: false,
            universenameduplicate: "",
            toggleSharePopover: false,
            toggleShare: false,
            toggleDuplicate: false,
            toggleDelete: false,
            togglePublish: false,
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            showLoader: true,
            selectMultiRowData: [],
            selected: new Set(),
            itemTotalCount: new Set(),
            clearCheckboxFilter: false,
            downloadActionButton: false,
            refreshActionButton: false,
            deleteActionButton: false,
            deletePublishActionButton: true,
            viewActionButton: false,
            duplicateActionButton: false,
            createUniverseTitle: "Create Reports",
            getData: [],
            page: 1,
            pageSize: 10,
            firstItem: "",
            lastItem: "",
            totalItems: 0,
            paginationComponentKey: 0,
            selectedCheckboxFilter: new Set(),
            rulesTableKey: 0,
            /**sorting */
            sortType: false,
            sortOrder: -1,
            sortColIndex: 3,
            orderByColumn: "submitdatetime",
            deleteReportButtonDisable: true,
            reportTemplateFilter: "",
            reportUniverseFilter: "",
            reportOwnerFilter: "",
            reportStatusFilter: "",
            filterSearchTemplate: [],
            filterSearchUniverse: [],
            filterSearchOwner: [],
            filterSearchStatus: [],
            selectedToDate: "",
            selectedFromDate: "",
            actionButtonKey:0,
            userName: "",

            // used for setting interval
            timer: null,

            getShareUniverseData: [
                {
                    id: 1,
                    name: "Share",
                    access: "read only",
                    button: "delete",
                },
                {
                    id: 2,
                    name: "Publish",
                    access: "edit",
                    button: "delete",
                },
                {
                    id: 3,
                    name: "Share",
                    access: "read only",
                    button: "delete",
                },
                {
                    id: 4,
                    name: "Publish",
                    access: "edit",
                    button: "delete",
                },
            ],

            counterDetails: {
                displayCounterDetails: true,
                count: 0,
                countText: "Selected",
                showVerticalLine: true,
            },

            buttonPanelDetails: [
                {
                    id: "RunNewReportButtonId",
                    buttonName: "Run New Report",
                    iconName: "play-circle",
                    iconColor: "defaultColor",
                    isDisabled: false,
                    showVerticalLine: true,
                    isText: false,
                    permission: "reports-run-new-report"
                },
                {
                    id: "ReRunReportButtonId",
                    buttonName: "Re-Run Report",
                    iconName: "refresh",
                    isDisabled: true,
                    iconColor: "defaultColor",
                    showVerticalLine: false,
                    isText: false,
                    permission: "reports-re-run-report"
                },
                {
                    id: "DownloadButtonId",
                    buttonName: "Download",
                    iconName: "download",
                    iconColor: "defaultColor",
                    isDisabled: true,
                    showVerticalLine: false,
                    isText: false,
                    permission: "reports-download"
                },
                {
                    id: "DeleteButtonId",
                    buttonName: "Delete",
                    iconName: "trash",
                    iconColor: "defaultColor",
                    isDisabled: true,
                    showVerticalLine: false,
                    isText: false,
                    permission: "reports-delete"
                },
            ],

            reportsHeader: [
                {
                    fieldName: "templatename",
                    text: "Template",
                    width: "180px",
                    sortable: true,
                },
                {
                    fieldName: "universename",
                    text: "Universe",
                    width: "200px",
                    sortable: true,
                },
                {
                    fieldName: "username",
                    text: "Owner",
                    width: "180px",
                    sortable: true,
                },
                {
                    fieldName: "submitdatetime",
                    text: "Date",
                    width: "150px",
                    sorted: -1,
                    sortable: true,
                },
                {
                    fieldName: "status",
                    text: "Status",
                    width: "120px",
                    sortable: true,
                },
                {
                    fieldName: "actions",
                    text: "Actions",
                    width: "120px",
                    align: "right",
                },
            ],

            reportsRow: [],

            filterObject: [
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
            ],
        };
    },

    props: {
        menuwidth: {
            type: String,
        },
    },

    watch: {
        clearCheckboxFilter() {
            this.itemTotalCount.clear();
            this.counterDetails.count = 0;
            this.clearCheckboxFilter = false;
        },
        // getOwnerReport(){
        //     this.getReportsOwnerFilter([]);
        // }
    },

    // this will clear the timer when we change the route
    beforeDestroy() {
        clearInterval(this.timer);
    },

    async mounted() {
        // temporary change - added for NEX-1610 
        if (sessionStorage.getItem("permissions")) {
            let permissions = JSON.parse(sessionStorage.getItem("permissions"));
            this.userName = permissions.userEmail;
        }

        let obj = {
            page: this.page,
            pageSize: this.pageSize,
            searchTemplate: this.reportTemplateFilter,
            searchUniverse: this.reportUniverseFilter,
            searchOwner: this.userName,
            searchStatus: this.reportStatusFilter,
            startDate: this.selectedFromDate,
            endDate: this.selectedToDate,
            orderBy: this.orderByColumn,
            sortType: this.sortType,
        };
        await this.fetchReportList(obj);
        let uniTempObject = {
            page: 1,
            pageSize: 0,
        };
        await this.fetchUniversesList(uniTempObject);
        await this.$store.dispatch(
            REPORTING_ACTIONS.FETCH_TEMPLATE_LIST,
            uniTempObject
        );
        await this.fetchStatusList();
        // await this.fetchOwnerReport();
        this.getReportResponse();
        this.showLoader = false;
        this.pollDataHandler();
    },

    computed: {
        ...mapGetters("reporting", [
            "getReport",
            "getUniverses",
            "getStatusList",
            // "getOwnerReport",
        ]),
        ...mapState({
            finalTemplateList(state) {
                return get(state, "reportingModule.templates.templates", []);
            },
            reportingStatus(state) {
                return get(state, "reportingModule.reportStatus.reportStatus", false);
            },
        }),
        permissions () {
            return this.$store.state.auth.permissions;
        },
        selectionStatus() {
            const filtered = this.reportsRow.filter(
                (row) => row.checked === true
            );
            return {
                isIndeterminate:
                    filtered.length > 0 &&
                    filtered.length < this.reportsRow.length,
                isAllSelected:
                    filtered.length === this.reportsRow.length &&
                    filtered.length != 0 &&
                    this.reportsRow != 0,
            };
        },

        cssVariables() {
            return {
                "--menuWidth": this.menuwidth,
            };
        },

        reportLength() {
            return this.reportsRow?.length;
        },

        topReRunReportIcon() {
            return this.buttonPanelDetails.filter(
                (x) => x.id === "ReRunReportButtonId"
            );
        },
        topDownloadIcon() {
            return this.buttonPanelDetails.filter(
                (x) => x.id === "DownloadButtonId"
            );
        },
        topDeleteIcon() {
            return this.buttonPanelDetails.filter(
                (x) => x.id === "DeleteButtonId"
            );
        },
        reportListStatus() {
            const statusArray = this.reportsRow.filter(sts => (sts.status === 'Pending' || sts.status === 'Running'));
            return statusArray;
        },
    },

    methods: {
        ...mapActions("reporting", [
            "fetchReportList",
            "fetchUniversesList",
            "fetchStatusList",
            // "fetchOwnerReport",
        ]),

        

        pollDataHandler() {
            this.timer = setInterval(() => {
                if(this.reportListStatus.length) {
                    this.pollData(this.reportListStatus);
                }
            }, 30000);
            
        },

        getReportResponse() {
            let response = this.getReport;
            this.reportsRow = response.data.reports;
            this.totalItems = response.data.total;
        },

        deleteReportChecked(e) {
            if (e == true) {
                this.deleteReportButtonDisable = false;
            } else if (e == false) {
                this.deleteReportButtonDisable = true;
            }
        },

        executeSortingLogicForMdsTable(colIndex) {
            this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

            if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
                this.$set(this.reportsHeader[this.sortColIndex], "sorted", 0);
                this.sortOrder = -1;
            }

            this.sortColIndex = colIndex;
            this.$set(this.reportsHeader[colIndex], "sorted", this.sortOrder);
        },

        handleSortEvent(colIndex, colName) {
            this.executeSortingLogicForMdsTable(colIndex);
            this.orderByColumn = colName;
            this.sortOrder == 1
                ? (this.sortType = true)
                : (this.sortType = false);
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

        async commonFunction() {
            this.showLoader = true;
            this.page = 1;
            var obj = {
                page: this.page,
                pageSize: this.pageSize,
                searchTemplate: this.reportTemplateFilter,
                searchUniverse: this.reportUniverseFilter,
                searchOwner: this.userName,
                searchStatus: this.reportStatusFilter,
                startDate: this.selectedFromDate,
                endDate: this.selectedToDate,
                orderBy: this.orderByColumn,
                sortType: this.sortType,
            };
            await this.fetchReportList(obj);
            let response = this.getReport;
            this.reportsRow = response.data.reports;
            this.totalItems = response.data.total;
            this.showLoader = false;
        },

        async reRunCommonFunction() {
            this.showLoader = true;
            var obj = {
                page: this.page,
                pageSize: this.pageSize,
                searchTemplate: this.reportTemplateFilter,
                searchUniverse: this.reportUniverseFilter,
                searchOwner: this.userName,
                searchStatus: this.reportStatusFilter,
                startDate: this.selectedFromDate,
                endDate: this.selectedToDate,
                orderBy: this.orderByColumn,
                sortType: this.sortType,
            };
            await this.fetchReportList(obj);
            let response = this.getReport;
            this.reportsRow = response.data.reports;
            this.totalItems = response.data.total;
            this.showLoader = false;
            this.pollDataHandler();
        },

        // this function calls createInterval() in every 5 seconds, which is used to send the status update request.
        async pollData(statusArray) {
            const statusIds = statusArray.reduce((obj, item) => {
                if (item.status === 'Pending') {
                    obj.pendingIds.push(item.guid)
                }
                if (item.status === 'Running') {
                    obj.runningIds.push(item.guid)
                }
                return obj;
            }, { pendingIds: [], runningIds: [] });
            // console.log(statusIds, 'statusIds');

            await this.$store.dispatch(REPORTING_ACTIONS.FETCH_REPORT_STATUS, {
                reportPendingIds: statusIds.pendingIds.toString(),
                reportRunningIds: statusIds.runningIds.toString()
            });
            
            if (this.reportingStatus && statusArray.length !== 0) {
                await this.commonFunction();
                if(this.reportListStatus.length) {
                    this.pollDataHandler();
                } else {
                    clearInterval(this.timer);
                }
            }
        },

        getReportsTemplateFilter(selectedFilter) {
            let response = this.finalTemplateList.templates;
            if (response !== undefined) {
                this.filterObject[0].filterHeader = "Template";
                this.filterObject[0].selectionType = "multiple";
                const data =
                    response &&
                    response.reduce((obj, item) => {
                        const id = item.templateid;
                        const lable = item.templatename;
                        obj.push({ id, lable, isChecked: false });
                        return obj;
                    }, []);
                data.map((x) => {
                    selectedFilter.forEach((y) => {
                        if (x.lable === y.value.lable) {
                            x.isChecked = true;
                        }
                    });
                });
                this.filterObject[0].filterList = data;
            }
        },

        getReportsUniverseFilter(selectedFilter) {
            let response = this.getUniverses.universelist;
            if (response !== undefined) {
                this.filterObject[1].filterHeader = "Universe";
                this.filterObject[1].selectionType = "multiple";
                const data =
                    response &&
                    response.reduce((obj, item) => {
                        const id = item.listid;
                        const lable = item.listdescription;
                        obj.push({ id, lable, isChecked: false });
                        return obj;
                    }, []);
                data.map((x) => {
                    selectedFilter.forEach((y) => {
                        if (x.lable === y.value.lable) {
                            x.isChecked = true;
                        }
                    });
                });
                this.filterObject[1].filterList = data;
            }
        },

        // getReportsOwnerFilter(selectedFilter) {
        //     let response = this.getOwnerReport.data;
        //     if (response !== undefined) {
        //         this.filterObject[2].filterHeader = "Owner";
        //         this.filterObject[2].selectionType = "multiple";
        //         const data =
        //             response &&
        //             response.reduce((obj, item, index) => {
        //                 const id = index;
        //                 const lable = item.email;
        //                 obj.push({ id, lable, isChecked: false });
        //                 return obj;
        //             }, []);
        //         data.map((x) => {
        //             selectedFilter.forEach((y) => {
        //                 if (x.lable === y.value.lable) {
        //                     x.isChecked = true;
        //                 }
        //             });
        //         });
        //         this.filterObject[2].filterList = data;
        //     }
        // },

        getReportsDateRangeFilter() {
            this.filterObject[3].filterHeader = "Date Range";
            this.filterObject[3].selectionType = "daterange";
            const selctedFromDate = this.selectedFromDate ? new Date(this.selectedFromDate) : this.selectedFromDate;
            const selctedToDate = this.selectedToDate ? new Date(this.selectedToDate) : this.selectedToDate
            
              this.filterObject[3].filterList = [
                {
                    id: 1,
                    isdisabled: false,
                    lable: "from date",
                    value: selctedFromDate,
                    minMaxDates: {
                        min: new Date(2000, 0, 1),
                        max: new Date(2049, 11, 31),
                    },
                },
                {
                    id: 2,
                    isdisabled: false,
                    lable: "to date",
                    value: selctedToDate,
                    minMaxDates: {
                        min: new Date(2000, 0, 1),
                        max: new Date(2049, 11, 31),
                    },
                },
            ];
        },

        getReportsStatusFilter(selectedFilter) {
            let response = this.getStatusList.data;
            if (response !== undefined) {
                this.filterObject[4].filterHeader = "Status";
                this.filterObject[4].selectionType = "multiple";
                const data =
                    response &&
                    response.reduce((obj, item) => {
                        const id = item.statusid;
                        const lable = item.description;
                        obj.push({ id, lable, isChecked: false });
                        return obj;
                    }, []);
                data.map((x) => {
                    selectedFilter.forEach((y) => {
                        if (x.lable === y.value.lable) {
                            x.isChecked = true;
                        }
                    });
                });
                this.filterObject[4].filterList = data;
            }
        },

        dateRange(fromDate, toDate) {
            this.page = 1;
            this.clearAllHandleSelectEventCheckox();
            this.selectedFromDate = fromDate.toLocaleDateString();
            this.selectedToDate = toDate.toLocaleDateString();
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

        async paginateTable(arg) {
            this.showLoader = true;
            this.page = arg.page;
            this.pageSize = arg.pageSize;
            this.firstItem = arg.firstItem;
            this.lastItem = arg.lastItem;
            var obj = {
                page: this.page,
                pageSize: this.pageSize,
                searchTemplate: this.reportTemplateFilter,
                searchUniverse: this.reportUniverseFilter,
                searchOwner: this.userName,
                searchStatus: this.reportStatusFilter,
                startDate: this.selectedFromDate,
                endDate: this.selectedToDate,
                orderBy: this.orderByColumn,
                sortType: this.sortType,
            };
            await this.fetchReportList(obj);
            let response = this.getReport;
            this.reportsRow = response.data.reports;
            this.totalItems = response.data.total;
            this.selectedDataMatched();
            this.showLoader = false;
        },

        selectedDataMatched() {
            if (this.itemTotalCount.size != 0) {
                for (let item of this.itemTotalCount) {
                    for (let i = 0; i < this.reportsRow.length; i++) {
                        if (this.reportsRow[i].guid == item.guid) {
                            this.reportsRow[i] = item;
                        }
                    }
                }
            }
        },

        closeFilterPanel() {
            this.checkFilterOpen = false;
            this.showFilterPanel = false;
        },

        openFilterPannel(selectedFilter) {
            this.checkFilterOpen = true;
            this.showFilterPanel = true;
            this.getReportsTemplateFilter(selectedFilter);
            this.getReportsUniverseFilter(selectedFilter);
            // this.getReportsOwnerFilter(selectedFilter);
            this.getReportsStatusFilter(selectedFilter);
            this.getReportsDateRangeFilter();
        },

        async singalDownloadActionButton(rowDetails) {
            let response = await downloadReport(rowDetails.guid);
            if (response.data !== undefined) {
                const disposition = response.headers["content-disposition"];
                let filename = "";
                if (disposition && disposition.indexOf("attachment") !== -1) {
                    const filenameRegex =
                        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, "");
                    }
                }
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(link.href);
            }
        },

        async singalRefreshActionButton(rowDetails) {
            this.showLoader = true;
            let response = await reRunReport(rowDetails.guid);
            if (response.isError == false) {
                this.page = 1;
                this.reRunCommonFunction();
                this.paginationComponentKey += 1;
                this.actionButtonKey += 1;
            } else {
                this.showLoader = false;
                this.notificationMessage = response.data.message;
                this.notificationType = "error";
                this.notificationKey = "error-default";
                this.showNotification = true;
            }
            this.showLoader = false;
        },

        singalDeleteActionButton(rowDetails) {
            this.selectMultiRowData.length = 0;
            this.itemTotalCount.clear();
            if (this.selectMultiRowData.length == 0) {
                this.selectMultiRowData.push(rowDetails);
            }
            this.deleteReportButtonDisable = true;
        },

        deleteReportNo() {},

        async deleteReportYes() {
            this.showLoader = true;
            let array = [];
            this.selectMultiRowData.forEach((item) => {
                array.push(item.guid);
            });
            var Guids = array.join(",");
            let response = await deleteReport(Guids);
            // await this.fetchOwnerReport();
            const obj = {
                response,
                ntfnMsg: "The report has been deleted.",
                ntfnType: "informational",
                ntfnKey: "informational-example",
            };
            this.notificationResponse(obj);
        },

        async notificationResponse({ response, ntfnMsg, ntfnType, ntfnKey }) {
            if (response.isError == false) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.reset();
                this.page = 1;
                this.commonReportFun();
                this.paginationComponentKey += 1;
                this.showLoader = false;
            } else {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = response.data.message;
                this.notificationType = "error";
                this.showNotification = true;
            }
        },

        commonReportFun() {
            this.showLoader = true;
            this.commonFunction();
            this.selected.clear();
            this.clearAllHandleSelectEventCheckox();
        },

        onClickPanelButton(event) {
            if (event === "Run New Report") {
                this.runNewReport();
            } else if (event === "Re-Run Report") {
                this.reRunReport();
            } else if (event === "Download") {
                this.downloadReport();
            } else if (event === "Delete") {
                this.deleteReports();
            }
        },

        runNewReport() {
            this.$router.push({
                name: "Reporting Run Report",
            });
        },

        async reRunReport() {
            this.showLoader = true;
            let array = [];
            for (let i = 0; i < this.selectMultiRowData.length; i++) {
                array.push(this.selectMultiRowData[i].guid);
            }
            let data = array.join(",");
            let response = await reRunReport(data);
            if (response.isError == false) {
                this.page = 1;
                this.reRunCommonFunction();
                this.paginationComponentKey += 1;
                this.clearAllHandleSelectEventCheckox();
            } else {
                this.showLoader = false;
                this.notificationMessage = response.data.message;
                this.notificationType = "error";
                this.notificationKey = "error-default";
                this.showNotification = true;
            }
            this.showLoader = false;
        },

        async downloadReport() {
            this.showLoader = true;
            let array = [];
            for (let i = 0; i < this.selectMultiRowData.length; i++) {
                array.push(this.selectMultiRowData[i].guid);
            }
            let response = await downloadReports(array);
            if (response.isError == false) {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `report.zip`);
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(link.href);
                this.showLoader = false;
            } else {
                this.showLoader = false;
                var val= (response.data).text()
                val.then(result => {
                    const obj = JSON.parse(result);
                    this.notificationMessage = obj.message;
                    this.notificationType = "error";
                    this.notificationKey = "error-default";
                    this.showNotification = true;                
                })            
            }
        },

        deleteReports() {
            this.toggleDelete = !this.toggleDelete;
            this.deleteReportButtonDisable = true;
        },

        disableRowIcons(params) {
            this.downloadActionButton =
                params === "singleSelect" ? true : false;
            this.refreshActionButton = params === "singleSelect" ? true : false;
            this.deleteActionButton = params === "singleSelect" ? true : false;
            this.viewActionButton = params === "singleSelect" ? true : false;
            this.duplicateActionButton =
                params === "singleSelect" ? true : false;
        },

        disableTopIcons(params) {
            this.topReRunReportIcon[0].isDisabled =
                params === "singleSelect" ? false : true;
            this.topDownloadIcon[0].isDisabled =
                params === "singleSelect" ? false : true;
            this.topDeleteIcon[0].isDisabled =
                params === "singleSelect" ? false : true;
        },

        handleRowSelectEvent(index, event, row) {
            this.selectMultiRowData = this.reportsRow.filter(
                (el) => el.checked
            );
            this.$set(this.reportsRow[index], "checked", event);
            if (event) {
                this.itemTotalCount.add(row);
                this.selectMultiRowData = Array.from(this.itemTotalCount);
            } else {
                this.itemTotalCount.delete(row);
                this.selectMultiRowData = Array.from(this.itemTotalCount);
            }
            if (this.selectMultiRowData.length === 1) {
                this.disableTopIcons("singleSelect");
                this.disableRowIcons("singleSelect");
            }
            if (this.selectMultiRowData.length > 1) {
                this.disableTopIcons("singleSelect");
                this.disableRowIcons("singleSelect");
            }
            if (!this.selectMultiRowData.length) {
                this.disableRowIcons("noSelect");
                this.disableTopIcons("noSelect");
            }

            // Delete
            const disableDelete =
                this.selectMultiRowData.length &&
                this.selectMultiRowData.some((item) => item.status !== "Completed");
            if (!disableDelete && disableDelete !== 0) {
                this.buttonPanelDetails[2].isDisabled = false;
            } else {
                this.buttonPanelDetails[2].isDisabled = true;
            }
            
            this.counterDetails.count = this.itemTotalCount.size;
        },

        handleMultiSelection(event) {
            for (let i = 0; i < this.reportsRow.length; i++) {
                this.$set(this.reportsRow[i], "checked", event.target.checked);
            }
        },

        clearAllHandleSelectEventCheckox() {
            this.selectMultiRowData.length = 0;
            this.counterDetails.count = 0;
            this.itemTotalCount.clear();
            this.disableRowIcons("noSelect");
            this.disableTopIcons("noSelect");
        },

        multiSelectFilterChange(event, value, headerTypeDetails) {
            this.page = 1;
            this.getReportsDateRangeFilter();
            for (let i = 0; i < this.filterObject.length; i++) {
                if (this.filterObject[i].filterHeader == headerTypeDetails) {
                    for (let j = 0; j < this.filterObject[i].filterList.length; j++) {
                        if (this.filterObject[i].filterList[j].lable == value.lable) {
                            this.filterObject[i].filterList[j].isChecked = event;
                        }
                    }
                }
            }
            this.clearAllHandleSelectEventCheckox();
            if (event == true) {
                this.showLoader = true;
                if (headerTypeDetails == "Template") {
                    this.filterSearchTemplate.push(value.lable);
                } else if (headerTypeDetails == "Universe") {
                    this.filterSearchUniverse.push(value.lable);
                } else if (headerTypeDetails == "Owner") {
                    this.filterSearchOwner.push(value.lable);
                } else if (headerTypeDetails == "Status") {
                    this.filterSearchStatus.push(value.lable);
                }
                this.reportTemplateFilter = this.filterSearchTemplate.join(",");
                this.reportUniverseFilter = this.filterSearchUniverse.join(",");
                this.reportOwnerFilter = this.filterSearchOwner.join(",");
                this.reportStatusFilter = this.filterSearchStatus.join(",");
                this.commonFunction();
                this.paginationComponentKey += 1;
            } else if (event == false) {
                this.showLoader = true;
                if (headerTypeDetails == "Template") {
                    for (let i = 0; i < this.filterSearchTemplate.length; i++) {
                        if (this.filterSearchTemplate[i] === value.lable) {
                            this.filterSearchTemplate.splice(i, 1);
                        }
                    }
                } else if (headerTypeDetails == "Universe") {
                    for (let i = 0; i < this.filterSearchUniverse.length; i++) {
                        if (this.filterSearchUniverse[i] == value.lable) {
                            this.filterSearchUniverse.splice(i, 1);
                        }
                    }
                } else if (headerTypeDetails == "Owner") {
                    for (let i = 0; i < this.filterSearchOwner.length; i++) {
                        if (this.filterSearchOwner[i] == value.lable) {
                            this.filterSearchOwner.splice(i, 1);
                        }
                    }
                } else if (headerTypeDetails == "Status") {
                    for (let i = 0; i < this.filterSearchStatus.length; i++) {
                        if (this.filterSearchStatus[i] == value.lable) {
                            this.filterSearchStatus.splice(i, 1);
                        }
                    }
                }
                this.reportTemplateFilter = this.filterSearchTemplate.join(",");
                this.reportUniverseFilter = this.filterSearchUniverse.join(",");
                this.reportOwnerFilter = this.filterSearchOwner.join(",");
                this.reportStatusFilter = this.filterSearchStatus.join(",");
                this.commonFunction();
                this.paginationComponentKey += 1;
            }
        },

        reset() {
            let items = document.querySelectorAll(
                "input[type=checkbox]:checked"
            );
            for (var i = 0; i < items.length; i++) {
                if (items[i].type == 'checkbox') {
                    items[i].checked = false;
                }
            }
            this.$refs.fiterpanel.resetSelectedValue();
            this.selected.clear();
            this.selectMultiRowData.length = 0;
            this.itemTotalCount.clear();
            this.reportTemplateFilter = "";
            this.filterSearchTemplate = [];
            this.reportUniverseFilter = "";
            this.filterSearchUniverse = [];
            this.reportOwnerFilter = "";
            this.filterSearchOwner = [];
            this.reportStatusFilter = "";
            this.filterSearchStatus = [];
            this.selectedFromDate = "";
            this.selectedToDate = "";
            this.getReportsDateRangeFilter();
        },

        clearAllFilters() {
            this.clearAllHandleSelectEventCheckox();
            this.clearCheckboxFilter = true;
            this.reset();
            this.page = 1;
            this.commonFunction();
            this.paginationComponentKey += 1;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/styles.scss";

.isFilterOpen {width: 82%}

.searchInputLabel {
    font-family: Univers, HelveticaNeue, Helvetica Neue, Helvetica, Arial,
        sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
    font-weight: 600;
    color: #1e1e1e;
    margin-top: 0;
    margin-bottom: 8px;
}

.reports-header {
    margin-top: 16px;
    @include mds-level-2-heading($bold: false);
}

.reportsTable {
    margin-top: 13px;
}

.tableDataRow {
    padding: 0;
}

.leftBlock {
    width: 94%;
    position: relative;
    overflow: hidden;
    margin-left: 16px;
    margin-right: 71px;
}

.rightBlock {
    width: 55px;
    padding: 0;
    margin-left: calc(100vw - 55px - var(--menuWidth));
    min-height: 100vh;
    border: 1px solid #979797;
    border-top: 1px solid #cccccc;
    position: fixed;
    z-index: 1;
    background-color: white;
}

.showFilterRightBlock {
    width: 292px;
    margin-left: calc(100vw - 292px - var(--menuWidth));
    position: fixed;
    z-index: 1;
}

.showFilterLeftBlock {
    max-width: 96%;
    margin-left: 16px;
    margin-right: 71px;
}

.mds-data-table__header-selection___VueMDS3Demo {
    left: -1px;
    position: relative;
    vertical-align: bottom;
    width: 15px;
}

::v-deep .mds-data-table__header-selection___VueMDS3Demo {
    left: -1px;
    position: relative;
    text-align: left;
    vertical-align: bottom;
    width: 2%;
}

::v-deep .mds-data-table__table___VueMDS3Demo {
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
    color: #1e1e1e;
    display: table;
    max-width: 100%;
    table-layout: fixed;
    width: 100%;
    margin-top: 16px;
}

.actionButton {
    margin-left: 5px;
    margin-right: 5px;
}

.no-results-message {
    margin: auto;
    min-width: 50%;
    margin-top: 16px;
}

::v-deep
    .mds-data-table__cell--right___VueMDS3Demo
    .mds-data-table__header-cell-text___VueMDS3Demo,
.mds-data-table__cell--right___VueMDS3Demo
    .mds-data-table__header-cell-button___VueMDS3Demo {
    text-align: right;
    margin-right: 7px;
}

.move-cancel-save-button-container {
    margin-right: calc(292px - 55px);
}

.move-pagination-show-info::v-deep .mds-pagination__show-items___VueMDS3Demo {
    margin-right: calc(292px - 55px);
}

.pagination-component {
    position: fixed;
    width: calc(100vw - 88px - var(--menuWidth));
    bottom: 0;
}
</style>
